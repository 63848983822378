// for libs
.fa-envelope {
  top: 2px;
}

.fa-linkedin,
.fa-github {
  top: 1px;
}

.shadow {
  box-shadow: 0 0rem 1rem rgba(0, 0, 0, .25) !important;
}
