// layout
$scrollbar-width: 5px;

// colors
$color-white: white;
$color-grey-light: #d1d1d1;
$color-grey-dark: #666666;
$color-black: #575757;
$color-anchor-hover: black;
$color-yellow: rgb(255, 254, 246);
$color-yellow-n1: rgb(235, 234, 226);
$color-yellow-trans-light: rgba($color-yellow, .9);
$color-yellow-trans-med: rgba($color-yellow, .2);
$color-checkbox-hover: $color-grey-light;
$color-checkbox-checked: $color-grey-dark;

// misc
$box-shadow: 0 0 8px lightgrey;
$social-icon-size: 30px;
$border-thick: 2px;

// bootstrap overrides
$body-color: $color-black;
$border-radius: 0.5rem;
