@import 'variables';

@import 'bootstrap/scss/bootstrap';
@import 'animate.css/animate.css';
@import '@fortawesome/fontawesome-free/css/all.css';

@import 'mixins';
@import 'overrides';

:root {
  --animate-duration: .5s;
}

html,
body {
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Barlow', sans-serif !important;

  * {
    &:focus,
    &:focus-visible {
      outline: dotted;
    }
  }
}

footer {
  bottom: 0;
}

h1, h2, h3, h4, h5, h6, a {
  color: $color-grey-dark;
}

ul.unstyled-list li {
  list-style-type: none;
}

a {
  text-decoration: underline;
  @include transition-all;

  &:hover {
    color: $color-anchor-hover;
  }
}

small {
  letter-spacing: .02rem;
}

.main-bg {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-position: bottom;
  filter: grayscale(.9);
  opacity: 0;
}

#root,
.App {
  min-height: 100%;
}

.clickable {
  cursor: pointer;
}

.btn-grey-dark {
  background: $color-grey-dark;
  border-color: $color-grey-dark;
}

.bg-yellow {
  background: $color-yellow;
}

.bg-yellow-trans-light {
  background: $color-yellow-trans-light;
}

.bg-yellow-trans-med {
  background: $color-yellow-trans-med;
}

.bg-yellow-to-transparent-top {
  background: linear-gradient(to top, $color-yellow-n1, transparent);
}

.bg-darken {
  background: rgba(0,0,0,0.2) !important;
}

.border-grey-light {
  border-color: $color-grey-light !important;
}

.border-grey-dark {
  border-color: $color-grey-dark !important;
}

.border-thick {
  border-width: $border-thick !important;
}

.text-grey-dark {
  color: $color-grey-dark !important;
}

.profile {
  position: static;
}

.profile-background {
  background: $color-yellow-n1;
  // min-height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
}

.profile-content {
  &::-webkit-scrollbar {
    width: $scrollbar-width;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-grey-light;
    border-radius: $scrollbar-width / 2;
  }
}

.border-grey-light {
  border-color: $color-grey-light !important;
}

.profile-image {
  max-width: 180px;
  width: 100%;
  height: auto;
  filter: grayscale(100%);
}

.overlay {
  background: rgba(0,0,0,.8);
  z-index: 999;
}

.z-top {
  z-index: 9999;
}

// honeypot
#address {
  display: none;
}

.scroll-indicator {
  height: 25%;
  bottom: 0;
  right: $scrollbar-width;
  z-index: 999;
}

.contact-modal {
  overflow-y: scroll;
  max-height: 90vh;
}

.content-wrap {
  opacity: .98;
  box-shadow: 0 0 1rem 0.25rem rgba(0, 0, 0, .25);
}

.social-link-wrap {
  width: $social-icon-size;
  height: $social-icon-size;
}

@include media-breakpoint-up(md) {
  .profile {
    position: fixed;
    left: 0;
  }

  .profile-content {
    max-height: 90vh;
    overflow-y: auto;
  }

  .profile-image {
    max-width: 100%;
  }
}

@include media-breakpoint-up(lg) {
  .info-section-entry {
    opacity: .8;
    @include transition-all;

    &:hover {
      opacity: 1;
    }
  }
}

.social-link {
  &:hover,
  &.social-link-active {
    > *:after {
      opacity: 1;
      top: 0;
      transform: translateY(6px);
    }
  }
}

.social-link > * {
  &:after {
    content: "";
    position: absolute;
    top: 10px;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    border-bottom: solid $border-thick $color-black;
    @include transition-all;
  }
}

.position-fixed.fixed-bg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.border-dotted {
  border-style: dotted !important;
}

#skills li {
  white-space: nowrap;
}

.transition-all {
  @include transition-all;
}


/* Customize the label (the container) */
.checkmark-wrap {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkmark-wrap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  right: 100%;
  height: 25px;
  width: 25px;
  outline: 1px black solid;
}

/* On mouse-over, add a grey background color */
.checkmark-wrap:hover input ~ .checkmark {
  background-color: $color-checkbox-hover;
}

/* When the checkbox is checked, add a blue background */
.checkmark-wrap input:checked ~ .checkmark {
  background-color: $color-checkbox-checked;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark .fa {
  color: white;
  display: none;
}

/* Show the checkmark when checked */
.checkmark-wrap input:checked ~ .checkmark .fa {
  display: inline-block;
}

.opacity-0 {
  opacity: 0;
}

.opacity-5 {
  opacity: .5;
}

.transition-all {
  @include transition-all;
}

.info-section-wrap {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: inherit;
    transition: inherit;
    transform: scale(-0.95);
    filter: blur(50px);
  }

  &:hover,
  &:focus,
  &:focus-visible {

    &:before {
      background: rgba(255,255,255,1);
    }
  }
}

.read-more {
  letter-spacing: 0.025rem;
  text-decoration: underline;

  &:focus,
  &:focus-visible {
    opacity: 1;
  }
}
